import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header'
import Footer from '../containers/layout/footer'
import PageHeader from '../components/pageheader'
import CaseStudyArea from '../containers/global/case-study-area'
import ContactArea from '../containers/global/contact-area'
import ClientsArea from '../containers/global/clients-area'

const CaseStudiesPage = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO title="Case Studies"/>
    <Header/>
    <PageHeader 
        pageContext={pageContext} 
        location={location}
        title="Case Studies"
    />
    <main className="site-wrapper-reveal">
        <CaseStudyArea/>
        <ClientsArea />
        <ContactArea/>
    </main>
    <Footer/>
  </Layout>
)
 
export default CaseStudiesPage
 